<template>
  <main class="page_bg_grey blank-aside-js min-h-100vh w-100" :class = "(isOpenAside=='true')?'blank-aside':''">
    <v-container class="account_container">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="my-5">
          <h5>
            <b>{{ $t("Account Overview") }}</b> 
          </h5>
          <p class="fs14 mb-0">{{ $t("Home") }} - {{ $t("Account") }}</p>
        </div>
        <v-alert
          outlined
          type="error"
          text
          class="fs14 p-5"
        >
          <h6 class="mb-0"><b>{{ $t("We need your attention!") }}</b></h6>
          {{ $t("Your payment was declined. To start using tools, please") }} <span class="blue--text text--darken-1 font-weight-bold pointer">{{ $t("Add Payment Method") }}</span> 
        </v-alert>
      </div>
      <section v-if="user">
        <v-card
          class="pt-10"
        >
          <div class="px-10 d-flex account-info_block">
            <img v-if="user.photo" :src="user.photo" alt="" class="profile-img mb-5">
            <img v-else src="/assets/img/icons/face-man-outline.svg" alt="" class="profile-img mb-5">
            <div class="account-info">
              <h5 class="font-weight-bold">{{ user.first_name + ' ' + user.last_name }}</h5>
              <div class="d-flex flex-wrap">
                <div class="d-flex align-items-center">
                  <v-icon small>mdi-phone</v-icon>
                  <p class="fs12 mb-0 mx-3">{{ user.phone_number }}</p>
                </div>
                <div class="d-flex align-items-center account-email">
                  <v-icon small>mdi-email-outline</v-icon>
                  <p class="fs12 mb-0 mx-3">{{ user.email }}</p>
                </div>
                <div class="d-flex align-items-center">
                  <v-icon small>mdi-map-marker-outline</v-icon>
                  <p class="fs12 mb-0 mx-3">{{ user.street_address }}</p>
                </div>
              </div>
              <div class="mt-10">
                <h6 class="font-weight-bold blue-grey--text text--darken-1">{{ $t("Upcoming trips") }}</h6>
                <div class="d-flex flex-wrap">
                  <TripsCard
                    v-for="(card, i) in TripsCardsData" 
                    :key="i"
                    :start="card.start"
                    :end="card.end"
                    :date="card.date"
                    :passenger="card.passenger"
                    class="mb-5 trips_card"
                  >
                  </TripsCard>
                </div>
              </div>
            </div>
          </div>
          <v-tabs
            v-model="tab"
            align-with-title
            class="mt-10"
            show-arrows
          >
            <!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->
            <v-tab
              v-for="item in tabTitles"
              :key="item"
              class="m-0 font-weight-bold text-none"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-card>
    
        <v-tabs-items class="mt-15 over-flow-initial bg-transparent" v-model="tab">
          <v-tab-item>
            <OverviewTab
              :fullName="user.first_name + ' ' + user.last_name"
              :phone="user.phone_number"
              :email="user.email"
              :currency="user.currency || '---'"
              :country="countryName? countryName.label : '---'"
              :address="user.street_address"
              :communication="user.communication || '---'"
              :allow_marketing="user.allow_marketing"
            />
          </v-tab-item>
          <v-tab-item>
            <SettingsTab
              :user="user"
              @updateEndUserCallBack="updateEndUserCallBack"
            />
          </v-tab-item>
          <v-tab-item>
            <BillingTab />
          </v-tab-item>
          <v-tab-item>
            <InvoiceTab />
          </v-tab-item>
          <v-tab-item>
            <MyTripsTab />
          </v-tab-item>
        </v-tabs-items>
      </section>
    </v-container>
  </main>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { CountriesConst } from "@/common/constants.js";
  import TripsCard from '@/components/endUser/account/TripsCard';
  import OverviewTab from '@/components/endUser/account/OverviewTab';
  import SettingsTab from '@/components/endUser/account/SettingsTab';
  import BillingTab from '@/components/endUser/account/BillingTab';
  import InvoiceTab from '@/components/endUser/account/InvoiceTab';
  import MyTripsTab from '@/components/endUser/account/MyTripsTab';

  export default {
    name: 'ResellerAccount',
    components: {
      TripsCard,
      OverviewTab,
      SettingsTab,
      BillingTab,
      InvoiceTab,
      MyTripsTab
    },
    data() {
      return {
        countryList: CountriesConst,
        TripsCardsData: [
          {  
            start: 'Tel Aviv', 
            end: 'Porto',
            date: '25/02/2023 - 02/03/2023',
            passenger: 2
          },
          {  
            start: 'Tel Aviv', 
            end: 'Berlin',
            date: '25/03/2023 - 02/04/2023',
            passenger: 4
          }
        ],
        overview: {
          fullName: "Jon",
          phone: "+972-54-3095-860",
          email: "yossef@Pay Analog.com",
          currency: "ILS",
          country: "Israel",
          address: "West Hollywood 54, California, 91210",
          communication: "Email, Phone",
          allow_marketing: "Allow Changes"
        },
        tab: null,
        tabTitles: [
          this.$t("Overview"), 
          this.$t("Settings"), 
          this.$t("Billing"), 
          this.$t("Invoices"), 
          this.$t("My trips")
        ]
      }
    },
    computed: {
      ...mapState({
        user: (state) => state.end_user.user,
      }),
      storageUser() {
        return JSON.parse(localStorage.getItem("User")) || {};
      },
      countryName() {
        return this.countryList.find(el => {
          return el.code == this.user.country;
        });
      }
    },
    async created() {
      // if (!this.user || (this.user && Object.keys(this.user).length === 0)) {
      //   await this.getEndUser(this.storageUser?.id);
      // }
    },
    methods: {
      ...mapActions('end_user', {
        getEndUser: 'getEndUser'
      }),
      updateEndUserCallBack() {
        this.getEndUser(this.storageUser?.id);
      }
    }
  }
</script>

<style lang="scss">
  p {
    margin-bottom: 0 !important;
  }
  label {
    font-size: 14px;
  }
  .border-bottom-gray {
    border-bottom: 1px solid #E0E0E0;
  }
  .header-tabs {
    width: auto;
    flex: unset;
  }
</style>

<style lang="scss" scoped>
  .account_container {
    max-width: 1300px;
    padding-top: 20px;
    .account-info_block {
      @media screen and (max-width: 540px) {
        flex-direction: column;
      }
      .trips_card {
        margin-right: 30px;
      }
      .profile-img {
        width: 150px;
        height: 150px;
        object-fit: contain;
      }
      .account-info {
        margin: 0 24px;
        @media screen and (max-width: 480px) {
          margin: 0;
        }
      }
      .account-email {
        margin: 0 40px;
        @media screen and (max-width: 640px) {
          margin: 0;
        }
      }
    }
  }
  .rtl-type {
    .account-info_block { 
      .trips_card {
        margin-left: 30px;
        margin-right: 0;
      }
    }
  }
</style>